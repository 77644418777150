import React from 'react'
import { FaFacebookF, FaLinkedinIn,FaSteamSquare } from "react-icons/fa";
import { contactImg } from "../../assets/Index"
import { BsInstagram } from "react-icons/bs";

const ContactLeft = () => {
    return (

        <div className='w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#1e2024] to-[#23272b] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center'>
            <img
                className="w-full h-64 object-cover rounded-lg"
                src={contactImg}
                alt="contactImg"
            />
            <div className="flex flex-col gap-4">
                <h3 className="text-3xl font-bold text-white">Damian Deligdisch</h3>
                <p className="text-lg font-normal text-gray-400">
                QA Automation / Full stack Developer.
                </p>
                <p className="text-base text-gray-400 tracking-wide">
                You can contact us through Linkedin, by mail or by sending the corresponding form.
                </p>
                {/*<p className="text-base text-gray-400 flex items-center gap-2">
                    Phone: <span className="text-lightText">+43 676 5274822</span>
                </p>/*/}
                <p className="text-base text-gray-400 flex items-center gap-2">
                    Email: <a href="https://mail.google.com/" className="text-lightText">ddeligdisch@gmail.com</a>
                </p>
                <div className="flex flex-col gap-4">
                    <h2 className="text-base uppercase font-titleFont mb-4">Find me in</h2>
                    <div className="flex gap-4">
                        <span className="bannerIcon">
                            <FaFacebookF onClick={() => window.open('https://www.facebook.com', '_blank')} />
                        </span>
                        <span className="bannerIcon">
                            <BsInstagram onClick={() => window.open('https://www.instagram.com/damiandel_ok/', '_blank')} />
                        </span>
                        <span className="bannerIcon" >
                            <FaLinkedinIn onClick={() => window.open('https://www.linkedin.com', '_blank')} />
                        </span>
                        <span className="bannerIcon" >
                            <FaSteamSquare onClick={() => window.open('https://steamcommunity.com/', '_blank')} />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactLeft
