import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      <div>
        <div className="py-6 lgl:py-4 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2015 - 2023</p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Quality Assurance Automation Engineer"
            subTitle="Globant · Full-time - (2020 - April 2022)"
            result="Argentina"
            des="The application of good practices of BDD Tests, ADD and continuous integration was promoted. Participated in the cooperative development process of a testing framework. Construction of test architecture.
            Management of TEST AUTOMATION Tools (Selenium WebDriver +Serenity BDD + Maven+ JAVA + Cucumber ). AUTOMATION API Testing were created and run with POSTMAN + Newman (JavaScript). Bitbucket was used as the main repository, then migrations to GitLab.
            As an agile SCRUM + XP methodology."
          />
          <ResumeCard
            title="Quality Assurance Automation Engineer"
            subTitle="G&L Group · Full-time - (2019 - 2020)"
            result="Argentina"
            des="Referring to QA Automation, currently training 12 resources of different consultants that are in the project.

            Management of TEST AUTOMATION Tools (Selenium WebDriver + Java, running in Azured Devops and AWS). 
            Creation of test environments, for the automation of services and screens, through Selenium WebDriver.
            
            AUTOMATION APIs were created and run with POSTMAN + Newman. Regressions and monitoring were performed with the Azured Devops pipeline."
          />
          <ResumeCard
            title="Quality Assurance Automation Engineer"
            subTitle="Banco Supervielle  · Full-time - (2018 - 2019)"
            result="Argentina"
            des="Management of AUTOMATION TESTING in BANTOTAL core (Selenium WebDriver + Java + Autolt, running in Jenkins as IC).
            Automation of the AS / 400 system through Mainframe."
          />
        </div>
      </div>
      <div>
        <div className="py-6 lgl:py-4 font-titleFont flex flex-col gap-4"></div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="QA Automation Engineer"
            subTitle="Frávega · Full-time - (2018)"
            result="Argentina"
            des="Management of TEST AUTOMATION Tools (Selenium WebDriver + Java, SoapUI, ReadyAPI, running in Jenkins as IC).

            Obtaining and interpreting metrics and reports based on the number of users required (Jmeter + ReadyAPI).
            Execution of tests in WEB systems.
            Control and monitoring of defects.
            
            Specifications of the work routine.
            - Carry out test cases, test matrices, functional tests.
            - Control and monitoring of the errors presented in each type of tests.
            -Generate the necessary documentation that generates evidence of the tests and results."
          />
          <ResumeCard
            title="Web designer"
            subTitle="Grupo Sol · Full-time - (2018)"
            result="Argentina"
            des="Update of company websites: • Gruposol.com.ar. • Jornadasase.com.ar. • Nbsbancosyseguros.com • Jornadasase.com.ar. Creation of Banners and newsletters for the website, campaigns on social networks and Email Marketing."
          />
          <ResumeCard
            title="Web designer"
            subTitle="Freelance (2001 - 2010)"
            result="Argentina"
            des="Content generation (Notes, finds, interviews), for the site soloriver.net. Coordination of social networks; Facebook, Twitter, Taringa, Youtube and development of material for them. Interaction with followers and participants. In charge of Google Adsense and Youtube account. Administration of statistics and site traffic. Website creation and development Ganbaru.com.ar/ -Soloriver.net/ - Fernandoengelberg.com/ - Cmwcupulas.com/ - Estaciondeaire.com.ar/ Mlreciclado.com ."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Experience;
