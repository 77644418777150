import React from 'react'
import Title from '../layouts/Title'
import { projectCypress, projectApiTest,projectWebdriverIO, projectRestAssured,projectAppiumWebIo,projectPlaywright} from "../../assets/Index"
import ProjectsAutomation from './ProjectsAutomation';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="QA Automation projects, with the latest technologies used"
          des="QA Automation Engineer"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
      <ProjectsAutomation
          title="Web Automation - Playwright "
          des="Automated testing framework using Playwright, Allure, AWS, with scripts in JS/TS for web applications"
          src={projectPlaywright}
          to='https://github.com/dami77del/playwright'
        />
        <ProjectsAutomation
          title="Web automation - Cypress Project "
          des=" Web Automation with Cypress Test Runner features + Cypress Integration Testing + Cypress API Testing + Mocha & Cucumber"
          src={projectCypress}
          to='https://github.com/dami77del/automationCypress'
        />
        <ProjectsAutomation
          title="API Automation - SuperTest Project "
          des="Write API automation tests with Javascript using Mocha, Chai JS, and SuperTest HTTP Library"
          src={projectApiTest}
          to='https://github.com/dami77del/API_Automation'
        />
        <ProjectsAutomation
          title="Web automation - WebdriverIO "
          des="Cucumber framework Using WebDriverIO to support BDD with Page Object Model. BDD - Gherkin - Step definitions"
          src={projectWebdriverIO}
        />
        <ProjectsAutomation
          title="Mobile automation - WebdriverIO "
          des="This project is structured for Mobile Automation Testing using WebdriverIO & Appium integration"
          src={projectAppiumWebIo}
        />
        <ProjectsAutomation
          title="API Automation - Rest Assured 2024"
          des="API Testing with Rest Assured in Visual Studio using JAVA, including PayPal, GitHub, Reqres.in, and Fakestoreapi"
          src={projectRestAssured}
          to='https://github.com/dami77del/rest_assured_java_vs_2024'
        />

      </div>
    </section>
  );
}

export default Projects