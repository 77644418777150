import React from "react";
import {motion} from "framer-motion"
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      <div>
        <div className="py-6 lgl:py-4 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2015 - 2022</p>
          <h2 className="text-3xl md:text-4xl font-bold">Education</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10 lgl:px-10 ">
          <ResumeCard
            title="Application Development Course"
            subTitle="CoderHouse - (2022 - Present)"
            result="Remote - ESP"
            des="This course provides the knowledge and bases to create bridge applications with the capacity to deploy both for Android and iOS, using React Native, Javascript and prototyping software such as Sketch or Adobe Xd. You will connect to APIs and learn the ability to sync with Firebase or Realm db."
          />
          <ResumeCard
            title="JavaScript course"
            subTitle="CoderHouse - (2022)"
            result="Remote - ESP"
            des="In this course learn the fundamentals of the most widely used programming language today, with which it is possible to create applications of all kinds. 
            Thanks to this course, you will create interactive web solutions and transfer the knowledge of the course to any JavaScript framework"
          />
          <ResumeCard
            title="Computer Systems Analyst"
            subTitle="Da Vinci institute - (2016 - 2019)"
            result="Argentina"
            des="The career of Systems Analyst at Da Vinci School is aimed at training professionals with a strong knowledge of programming technologies and modern databases, present in the market, with tools commonly used in companies. It focuses on different types of software: desktop, web systems and mobile applications. They also teach aspects of software quality, computer security, analysis strategies and project design, which add to the student a unique profile, suitable for managerial and decision-making positions."
          />
        </div>
      </div>
      <div>
        <div className="py-6 lgl:py-4 font-titleFont flex flex-col gap-4">
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title=" Development Programming on Android"
            subTitle="Educacion IT (2017)"
            result="Argentina"
            des="Activities and societies: Installation of all the work environment necessary for Android programming.
            Use the main components of Android: Activity, View and ViewGorups, Intents Services, Content Providers, BroadcastReceiver, Widgets.
            Design graphic interfaces for Android developments.
            Develop menus, action bars and contextual menus.
            Work with persistence of Information in the BDD.
            Upload applications to Google Play."
          />
          <ResumeCard
            title=" Computer Security Career"
            subTitle="Educacion IT (2016)"
            result="Argentina"
            des="Training in computer security is composed of a wide variety of courses that teach from the fundamental concepts to the most advanced attack techniques. You will learn to mitigate vulnerabilities and develop the best recommended protection measures for the IT infrastructure. The content of the race has been formulated based on the objectives of the CISSP and CEH certifications, international quality standards in computer security."
          />
          <ResumeCard
            title="FrontEnd Web Designer"
            subTitle="Educacion IT (2015 - 2016)"
            result="Argentina"
            des="Beginning with the study of the HTML5 language, the career is aimed at an integral formation in the concepts of Web layout and responsive design for all types of screens and mobile devices."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Education;