import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import "./loader.css";

const Loader = () => {
  const [text] = useTypewriter({
    words: [
      "Bienvenidos",
      "Willkommen",
      "Welcome",
      "Benvenuto",
      "ברוך הבא",
      "いらっしゃいませ",
      "Ласкаво просимо",
      "Hoş geldin",
    ],
    loop: true,
    typeSpeed: 5,
    deleteSpeed: 5,
    delaySpeed: 1500,
  });

  return (
    <div className="preloader">
      <div className="svg-wrapper">
        <h2 className="text-4xl font-bold text-white">
          {" "}
          <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          />
        </h2>
      </div>
    </div>
  );
};

export default Loader;
