import React from "react";
import { motion } from "framer-motion";
import { CV_EN} from "../../assets/Index"

const Achievement = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="py-12 font-titleFont flex gap-20"
    >
      <div>
        <div className="flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">CV in PDF format</p>
          <h2 className="text-4xl font-bold py-5">Download it depending on the language</h2>
        </div>
          <div className="flex gap-4">
            <span className="bannerIcon">
            <a className="text-2xl" href="CV" download={CV_EN}>EN</a>
            </span>
            <span className="bannerIcon">
            <a className="text-2xl" href="CV_EN" src="CV_EN">DE</a>
            </span>
          </div>
      </div>

    </motion.div>
  );
};

export default Achievement;