import React from 'react'
import Title from '../layouts/Title'
import Card from './Card'
import { AiFillIeSquare} from "react-icons/ai";
import { FaMobile, FaGlobe } from "react-icons/fa";
import {  SiBaremetrics } from "react-icons/si";
import { BsApp } from "react-icons/bs";
import { GoGitCompare } from "react-icons/go";
const Features = () => {
  return (
    <section
      id='features'
      className='w-full py-20 border-b-[1px] border-b-black'>
      <Title title="Features" des="What i do" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
        <Card
          title="Website Automation"
          des="Management of test Automation Tools (Selenium WebDriver + Serenity BDD + Maven+ JAVA + Cucumber)."
          icon={<AiFillIeSquare />}
        />
        <Card
          title="Automation API "
          des="Tests were created with POSTMAN + Newman (JavaScript) and stored in Bitbucket/Azure DevOps, then migrated to GitLab/AWS."
          icon={<BsApp />}
        />
        <Card
          title="Obtaining metrics"
          des="Obtaining and interpreting metrics and reports based on the number of users required (Jmeter + ReadyAPI)
          Control and monitoring of defects."
          icon={<SiBaremetrics />}
        />
        <Card
          title="Analyst QA functional"
          des="QA Analyst focused on functional testing based on customer requirements, writing and executing test cases, and incident reporting. Gained automation tools through daily company training."
          icon={<GoGitCompare />} 
        />
        <Card
          title="Mobile Development"
          des="App creation through react native (ios and android). Communication with Rest services, geolocation using Expo."
          icon={<FaMobile />}
        />
        <Card
          title="Web developer"
          des="I have developed full-stack applications using JavaScript (ES6), React JS, and Firebase, focusing on responsive design, API integrations, and performance optimization."
          icon={<FaGlobe />}
        />
      </div>
    </section>
  )
}

export default Features

