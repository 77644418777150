import { useEffect, useState } from "react";
import Navbar from './components/navbar/Navbar'
import Banner from './components/banner/Banner'
import Features from './components/features/Features'
import Projects  from "./components/projects/Projects";
import ProjectsAuto  from "./components/projects/ProjectsAuto";
import Resume from './components/resume/Resume';
import Contact from './components/contact/Contact';
import FooterBottom from './components/footer/FooterBottom';
import Loader from "./components/loader/Loader";

function App() {
   // loader state
   const [isLoading, setIsLoading] = useState(true);

   // Let create async method to fetch fake data
   useEffect(() => {
     const fakeDataFetch = () => {
       setTimeout(() => {
         setIsLoading(false);
       }, 4000);
     };
     fakeDataFetch();
    }, []);
  return   isLoading ? (
    <Loader />
      ) : (
    <div className='w-full h-auto bg-bodyColor text-ligthText px-4'>
      <div className='max-w-screen-xl mx-auto '>
      <Navbar/>
      <Banner/>
      <Features/>
      <Resume/>
      <Projects/>
      <ProjectsAuto/>
      <Contact/>
      <FooterBottom/>
      </div>
    </div>
  )
}

export default App;