import React from 'react'
import { useTypewriter, Cursor } from 'react-simple-typewriter'
import { SiJirasoftware, SiPostman,  SiSelenium } from 'react-icons/si'
import { FaFacebookF, FaLinkedinIn, FaReact } from 'react-icons/fa'
import { BsInstagram } from 'react-icons/bs'


const LeftBanner = () => {
        const [text] = useTypewriter({
          words: ["QA Automation.", "Full stack Developer."],
          loop: true,
          typeSpeed: 20,
          deleteSpeed: 10,
          delaySpeed: 2000,
        })
  return (
    <div className='w-full lgl:w-1/2 flex flex-col gap-20'>
    <div className='flex flex-col gap-5'>
      <h4 className='text-lg font-normal'> WELLCOME TO MY WORD </h4>
      <h1 className='text-6xl font-bold text-white '>Hi, I'm {""}
        <span className='text-designColor capitalize'> Damian Deligdisch</span>
      </h1>
      <h2 className='text-4xl font-bold text-white'>a <span>{text}</span>
        <Cursor
          cursorBlinking="false"
          cursorStyle="|"
          cursorColor="#ff014f"
        />
      </h2>
      <p className='text-base font-bodyFont leading-6 tracking-wide'>
      My last job in the area of ​​Software Automation Engineer was at Globant. His experience in companies was varied, in which he added a lot of work and personal experience.
      I studied the career of systems analyst and takes programming courses. This helped me keep up with the latest technologies required in the market.
      </p>
    </div>
    <div className='flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between'>
    <div>
      <h2 className='text-base uppercase font-titleFont mb-4'> Find me in</h2>
      <div className='flex gap-4'>
        <span className='bannerIcon'>
        <FaFacebookF onClick={() => window.open('https://www.facebook.com', '_blank')} />
        </span>
        <span className='bannerIcon'>
        <BsInstagram onClick={() => window.open('https://www.instagram.com/damiandel_ok/', '_blank')} />
        </span>
        <span className='bannerIcon'>
        <FaLinkedinIn onClick={() => window.open('https://www.linkedin.com/in/damian-del/', '_blank')} />
        </span>
      </div>
    </div>
    <div>
      <h2 className='text-base uppercase font-titleFont mb-4'> best skill on</h2>
      <div className='flex gap-4'>
      <span className='bannerIcon'>
          <SiSelenium />
        </span>
      <span className='bannerIcon'>
          <FaReact />
        </span>
        <span className='bannerIcon'>
          <SiPostman />
        </span>
        <span className='bannerIcon'>
          <SiJirasoftware />
        </span>
  
      </div>
    </div>
    </div>
  </div>
  )
}

export default LeftBanner