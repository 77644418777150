import React from 'react'
import Title from '../layouts/Title'
import {projectFive, projectSeven, projectAcht, projectNine, projectTen,projectStripeSanity  } from "../../assets/Index"
import ProjectsCard from './ProjectsCard';

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK"
          des="Full stack Developer"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="REST API Node.js & MongoDB"
          des="This backend is meticulously designed to offer a robust and secure service through a REST API built with Node.js"
          src={projectAcht}
          to='https://github.com/dami77del/backendProject'
        />
        <ProjectsCard
          title="NestJS Real-Time WebSocket"
          des="Backend project developed with NestJS,framework for Node.js that utilizes TypeScript"
          src={projectNine}
          to='https://github.com/dami77del/realtime-nestjs-server'
        />
        <ProjectsCard
          title="Newest Next Auth v5 (Auth.js)"
          des="Project Using Two Factor Authentication (2FA), Credential Login, Credential Login, OAuth (Google & Github)"
          src={projectTen}
          to='https://github.com/dami77del/next-auth-v5'
        />
       
        <ProjectsCard
           title="Branches Map - APP React Native"
           des="List of branches, taking photo and geolocation, with Sqlite database and verify"
           src={projectSeven}
          to='https://github.com/dami77del/realtime-nestjs-server'
        />
       <ProjectsCard
          title="Bakery - APP React Native"
          des="Product listing. Firebase + authentication with database"
          src={projectFive}
        />
        <ProjectsCard
          title="E-commerce with Stripe & Sanity.io"
          des="E-commerce site built with Next.js, Stripe, and Sanity.io for seamless experience"
          src={projectStripeSanity}
          to='https://github.com/dami77del/ecommerce-next14'
        />
      </div>
    </section>
  );
}

export default Projects